<template>
  <div class="modal modal_wrap" v-if="warningModal" @keyup.esc="close">
    <div class="modal_popup">
      <p class="text text_white">The following options have been changed from original set up and require review?
        <span class="modal__close" @click="close">
         <svg-icon name="times"/>
        </span>
      </p>
      <p class="text_white text">Press Cancel to review or Continue to proceed without review.</p>
      <div class="d-flex justify-content-between">
        <button class="btn btn_transparent" type="button" @click="close"><svg-icon name="solid/times" /> Cancel</button>
        <button class="btn btn_transparent" type="button" @click="save"><svg-icon name="solid/check" /> Continue</button>
      </div>
    </div>
  </div>
</template>

<script>

  import router from "../../router";
  import {mapGetters} from 'vuex';

  export default {
    name: "WarningOutOfSquare",

    data() {
      return {}
    },

    methods: {
      close(){
        this.$store.commit('createdOrder/warningOutOfSquare', false);
      },

      save(){
        if(!this.isEditOrderItem){
          this.$store.commit('createdOrder/warningOutOfSquare', false);
          this.$store.dispatch('createdOrder/putUpdates', this.$store.getters['createdOrder/createdOrder']);
          this.$store.dispatch('orderPreview/getPreview');
        }
        else if(this.isEditOrderItem){
          this.$store.commit('createdOrder/warningOutOfSquare', false);
          this.$store.dispatch('orderItem/update');
        }

      },
    },

    computed: {
      ...mapGetters(
        {
          warningModal: 'createdOrder/warningOutOfSquare',
        }
      ),

      isEditOrderItem() {
        return this.$store.getters['orderItem/get']('isUpdate');
      },
    },
  }
</script>

<style scoped lang="scss">
  p.text{
    position: relative;
    padding-right: 30px;
    &:last-child{
      padding: 0;
    }
  }

  .modal_popup{
    p{
      &:nth-child(2){
        padding: 0;
      }
    }
  }

  .modal__close{
    top: 0;
    right: 0;
  }
</style>
